import * as React from 'react';
import withRouter from "../../shared/components/common/withRouter";
// navigation
import { useNavigate } from "react-router-dom";
// style
import './landingPage.scss';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Calculator_Types } from '../../shared/static/constants/constant';

const LandingPage = () => {
    const [CalculatorType, setCalculatorType] = React.useState(Calculator_Types.Calculator_type_all);
    const [getSubscriptionPlan, setSubscriptionPlan] = React.useState([
        {
            'plan_name': "Embroidery Calculator",
            'plan_description': "Full access to the embroidery quote calculator only.",
            'price_per_month': '12.00',
            'price_per_year': '144.00',
            'calculator_type': 'Embroidery',
            'feature_list': [
                'Embroidery Quote Calculator'
            ]
        },
        {
            'plan_name': "Screen-printing Calculator",
            'plan_description': "Full access to the screen-printing quote calculator only.",
            'price_per_month': '12.00',
            'price_per_year': '144.00',
            'calculator_type': 'Screenprinting',
            'feature_list': [
                'Screen-printing Quote Calculator'
            ]
        },
        {
            'plan_name': "Screen-printing Shop Premium",
            'plan_description': "Access to the screen-printing quote calculator, quotes, and invoices. Generate quotes for 1 or multiple items from your quote calculator screen, send quote and generate invoices in 3 easy steps.",
            'price_per_month': '20.00',
            'price_per_year': '240.00',
            'calculator_type': 'Screenprinting',
            'feature_list': [
                'Dashboard',
                'Screen-Printing Quote Calculator',
                'Quotes',
                'Invoices'
            ]
        },
        {
            'plan_name': "Printshop Calculators",
            'plan_description': "Full access to the embroidery & screen-printing quote calculator only.",
            'price_per_month': '30.00',
            'price_per_year': '360.00',
            'calculator_type': 'All',
            'feature_list': [
                'Embroidery Quote Calculator',
                'Screen-printing Quote Calculator'
            ]
        },
        {
            'plan_name': "Full Print Shop Premium",
            'plan_description': "Access to the embroidery & screen-printing quote calculator, quotes, and invoices. Generate quotes for 1 or multiple items from your quote calculator screen, send quote and generate invoices in 3 easy steps.",
            'price_per_month': '40.00',
            'price_per_year': '480.00',
            'calculator_type': 'All',
            'feature_list': [
                'Dashboard',
                'Embroidery Quote Calculator',
                'Screen-Printing Quote Calculator',
                'Quotes',
                'Invoices'
            ]
        },
        {
            'plan_name': "Embroidery Shop Premium",
            'plan_description': "Access to the embroidery quote calculator, quotes, and invoices. Generate quotes for 1 or multiple items from your quote calculator screen, send quote and generate invoices in 3 easy steps.",
            'price_per_month': '20.00',
            'price_per_year': '240.00',
            'calculator_type': 'Embroidery',
            'feature_list': [
                'Dashboard',
                'Embroidery Quote Calculator',
                'Quotes',
                'Invoices'
            ]
        }
    ]);

    const [getCalculatorTypePlans, setCalculatorTypePlans] = React.useState([]);

    // navigation
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    const handleRegisterRedirect = () => {
        navigate('/register', { state: { islogin: false } });
    }

    React.useEffect(() => {
        handleCalculatorTypeChange(Calculator_Types.Calculator_type_all)
    }, []);

    // Function to handle calculator type changes
    const handleCalculatorTypeChange = (type) => {
        const selectedType = type;
        setCalculatorType(selectedType);
        const filteredPlans = getSubscriptionPlan.filter(
            (plan) => plan.calculator_type === selectedType
        );
        setCalculatorTypePlans(filteredPlans);
    };

    return (
        <React.Fragment>
            <div className="landing-page pt-md-4 mt-3">
                {/* header */}
                <header className='mb-3 mb-md-5 landing-page-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className="logo-sm">
                            <img src="/assets/images/Print_Shop_Master_small_logo.svg" alt="" height="40" />
                        </span>
                        <span className="logo-lg">
                            <img src="/assets/images/Print Shop Master-sidebar.svg" alt="" className="logo-large landing-page-logo" />
                        </span>

                        <div className="page-title-right py-1">
                            <ol className="breadcrumb m-0 d-flex align-items-center position-relative pe-0 justify-content-end">
                                <a href="https://youtube.com/channel/UCgxlk2ECFCHduC51XAek0Rw" target="_blank" className="fw-medium landing-page-link color-primary me-4" rel="noreferrer">
                                    Learn
                                </a>{" "}
                                <a href="mailto:info@theprintshopmaster.com" target="_blank" className="fw-medium landing-page-link color-primary me-4" rel="noreferrer">
                                    Contact
                                </a>{" "}
                                <button type="button" onClick={handleLoginRedirect} className="download-btn py-1 btn font-weight-700 btn-fill d-flex align-items-center">
                                    Login
                                </button>
                            </ol>
                        </div>
                    </div>
                </header>
                {/* section 1 */}
                <div className='d-flex justify-content-center text-center'>
                    <h1 className='title-text mt-4'>Print Shop Software that helps shop <span className='title-text-span'>Efficiency.</span></h1>
                </div>
                <div className='d-flex justify-content-center text-center'>
                    <p className='detail-text mt-3 mb-0'>
                        We help shops quote faster, get paid, and enjoy the printing process more! See your profit margins in real time, add multiple items to quotes, upload mock-ups, and move clients through the approval process faster.
                    </p>
                </div>
                <div className='text-center'>
                    <img src="/assets/images/main_img.png" className='main-img' alt="" height="40" />
                </div>
                {/* pricing section */}
                <p className='horizontal-line'><span className='horizontal-line-text'>Pricing</span></p>
                <div className='mt-5 d-flex justify-content-center text-center'>
                    <h1 className='pricing-sec-title title-text mt-4'>Run an embroidery or screenprinting quote in minutes. You can also run quote on both embroidery and screen printing together!  <span className='title-text-span'>Being able to do them in one place saves your time and money!</span></h1>
                </div>
                <div className="mb-0 ">
                    <div className="d-flex type-options-switch  card-grid">
                        <div className="d-flex align-items-center">
                            <Card className="type-option-card mb-0 h-100 me-0 ">
                                <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0 ps-sm-auto">
                                    <label className=" card-radio-label">
                                        <input
                                            type="radio"
                                            name="type_option"
                                            id="embroidery"
                                            className="card-radio-input"
                                            readOnly
                                            value="Embroidery"
                                            checked={
                                                CalculatorType ===
                                                Calculator_Types.Calculator_type_embroidery
                                            }
                                            onChange={() => {
                                                handleCalculatorTypeChange(Calculator_Types.Calculator_type_embroidery);
                                            }}
                                        />
                                        <div className="card-radio card-radio-duration me-2 py-2 ">
                                            <span className="selected-radio-button">
                                                Embroidery
                                            </span>
                                        </div>
                                    </label>
                                </CardBody>
                            </Card>
                            <Card className="type-option-card mb-0 h-100 d-inline-block">
                                <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0">
                                    <label className=" card-radio-label">
                                        <input
                                            type="radio"
                                            name="type_option"
                                            id="screenprinting"
                                            className="card-radio-input"
                                            readOnly
                                            value="Screen Printing"
                                            checked={
                                                CalculatorType ===
                                                Calculator_Types.Calculator_type_screenprinting
                                            }
                                            onChange={() => {
                                                handleCalculatorTypeChange(Calculator_Types.Calculator_type_screenprinting);
                                            }}
                                        />
                                        <div className={`card-radio card-radio-duration me-2 py-2 ${CalculatorType !== Calculator_Types.Calculator_type_screenprinting ? 'blinking-animation' : ''}`}>
                                            <span className="selected-radio-button">
                                                Screen Printing
                                            </span>
                                        </div>
                                    </label>
                                </CardBody>
                            </Card>
                            <Card className="type-option-card mb-0 h-100 d-inline-block">
                                <CardBody className="pt-0 h-100 pb-0 pe-0 ps-0">
                                    <label className=" card-radio-label">
                                        <input
                                            type="radio"
                                            name="type_option"
                                            id="both"
                                            className="card-radio-input"
                                            readOnly
                                            value="Both"
                                            checked={
                                                CalculatorType ===
                                                Calculator_Types.Calculator_type_all
                                            }
                                            onChange={() => {
                                                handleCalculatorTypeChange(Calculator_Types.Calculator_type_all);
                                            }}
                                        />
                                        <div className={`card-radio card-radio-duration  ${CalculatorType !== Calculator_Types.Calculator_type_all ? 'blinking-animation' : ''}   py-2`}>
                                            <span className="selected-radio-button">
                                                Both
                                            </span>
                                        </div>
                                    </label>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className='mb-5 pb-4'>
                    <Row className='justify-content-center g-0'>
                        {
                            getCalculatorTypePlans && getCalculatorTypePlans.map((calculator, index) => (
                                <Col
                                    key={index}
                                    className={`col-12 col-md-6 d-flex justify-content-center   ${index == 0 ? 'justify-content-md-end pe-md-3' : 'justify-content-md-start ps-md-3'}`}
                                >
                                    <Card className={`pricing-card mb-0 ${index === 1 && 'mt-4 mt-md-0'} `}>
                                        <CardBody>
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <span className='type-span px-1'>
                                                        {calculator.plan_name}
                                                    </span>
                                                    <span className='color-primary text-end d-flex justify-content-end align-items-center'>
                                                        {
                                                            index === 1 && <span>
                                                                <img alt='' src='/assets/images/crown.png' className='crown-img' />
                                                                <span className='ms-2 font-weight-700 color-secondary-dark'>Popular</span>
                                                            </span>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="py-3 mt-1 price-div">
                                                    <h2 className="price font-weight-600 font-size-32 color-primary">
                                                        ${calculator.price_per_month}/
                                                        <span className="font-size-13">mo </span>
                                                    </h2>
                                                </div>
                                                <div className='pt-4 color-primary font-size-12 feature_list_container'>
                                                    {
                                                        calculator && calculator.feature_list && calculator.feature_list.map((cal) => (
                                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>{cal}</span> </p>
                                                        ))
                                                    }
                                                </div>
                                                <div className='text-center mt-4'>
                                                    <button className={` ${index === 0 ? 'btn btn-price-outline' : 'btn btn-price-fill'} font-size-16 font-weight-600 `} onClick={handleRegisterRedirect}>
                                                        Start Free Trial!
                                                    </button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
                {/* <div className='mb-5'>
                    <Row className='g-0'>
                        <Col className='col-12 col-md-6 d-flex justify-content-center justify-content-md-end pe-md-3'>
                            <Card className='pricing-card mb-0'>
                                <CardBody>
                                    <div>
                                        <div>
                                            <span className='type-span px-1'>
                                                Starter
                                            </span>
                                        </div>
                                        <div className="py-3 mt-1 price-div">
                                            <h2 className="price font-weight-600 font-size-32 color-primary">
                                                $15/
                                                <span className="font-size-13">mo </span>
                                            </h2>
                                        </div>
                                        <div className='pt-4 color-primary font-size-12'>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Run an embroidery quote in minutes</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Consistent Pricing for clients</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Know your profit margins</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Embroidery calculator only</span> </p>
                                        </div>
                                        <div className='text-center btn-div'>
                                            <button className='btn btn-price-outline font-size-16 font-weight-600' onClick={handleRegisterRedirect}>
                                                Start Free Trial!
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className='col-12 col-md-6 d-flex justify-content-center justify-content-md-start ps-md-3'>
                            <Card className='pricing-card mb-0 mt-4 mt-md-0'>
                                <CardBody>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <span className='type-span px-1'>
                                                Premium
                                            </span>
                                            <span className='color-primary text-end d-flex justify-content-end align-items-center'>
                                                <img alt='' src='/assets/images/crown.png' className='crown-img' />
                                                <span className='ms-2 font-weight-700 color-secondary-dark'>Popular</span>
                                            </span>
                                        </div>
                                        <div className="py-3 mt-1 price-div">
                                            <h2 className="price font-weight-600 font-size-32 color-primary">
                                                $25/
                                                <span className="font-size-13">mo </span>
                                            </h2>
                                        </div>
                                        <div className='pt-4 color-primary font-size-12'>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Run an embroidery quote in minutes</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Send pricing directly to client quotes</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Attach mock-ups</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Auto draft invoices</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Edit features</span> </p>
                                            <p className='mb-1'>  <img alt='' src='/assets/images/check.png' className='check-img' /> <span>Payment integration</span> </p>
                                        </div>
                                        <div className='text-center mt-4'>
                                            <button className='btn btn-price-fill font-size-16 font-weight-600' onClick={handleRegisterRedirect}>
                                                Start Free Trial!
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div> */}
                {/* feature section */}
                <p className='horizontal-line'><span className='horizontal-line-text'>Features</span></p>
                <div className='features-containter'>
                    <Row>
                        <Col className="col-12 col-md-6 d-flex justify-content-center flex-column pe-md-4 pe-lg-5">
                            <h2 className='features-title color-secondary font-weight-600'>Run a quote in minutes</h2>
                            <p className='w-100 detail-text mt-md-3 mt-0 mb-4 mb-md-0 '>
                                The Embroidery & Screen-printing Quote Calculator will save your shop time, provide job profit numbers, and assure consistent pricing.
                            </p>
                        </Col>
                        <Col className="col-12 col-md-6 ps-md-4 ps-lg-5">
                            <div className='p-2 features-img-div'>
                                <img alt='' src='/assets/images/quote-calculator-image.png' className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='margin-top-18 flex-column-reverse flex-md-row'>
                        <Col className="col-12 col-md-6 pe-md-4 pe-lg-5">
                            <div className='p-2 features-img-div'>
                                <img alt='' src='/assets/images/quote-image.png' className='img-fluid' />
                            </div>
                        </Col>
                        <Col className="co-12 col-md-6 d-flex justify-content-center flex-column ps-md-4 ps-lg-5">
                            <h2 className='features-title color-secondary font-weight-600'>Quotes</h2>
                            <p className='w-100 detail-text mt-md-3 mt-0 mb-4 mb-md-0 '>
                                Pricing from calculator is sent directly to a draft quote for your client, attach mock-ups and have a seamless client process.
                            </p>
                        </Col>
                    </Row>
                    <Row className='margin-top-18'>
                        <Col className="col-12 col-md-6 d-flex justify-content-center flex-column pe-md-4 pe-lg-5">
                            <h2 className='features-title color-secondary font-weight-600'>Invoicing</h2>
                            <p className='w-100 detail-text mt-md-3 mt-0 mb-4 mb-md-0 '>
                                Once a quote is approved by your client it will automatically appear in Draft Invoices ready to be reviewed and sent for payment through Stripe integration.
                            </p>
                        </Col>
                        <Col className="col-12 col-md-6 ps-md-4 ps-lg-5">
                            <div className='p-2 features-img-div'>
                                <img alt='' src='/assets/images/invoice-image.png' className='img-fluid' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='margin-top-18 flex-column-reverse flex-md-row'>
                        <Col className="col-12 col-md-6 pe-md-4 pe-lg-5">
                            <div className='p-2 features-img-div'>
                                <img alt='' src='/assets/images/people-image.png' className='img-fluid' />
                            </div>
                        </Col>
                        <Col className="col-12 col-md-6 d-flex justify-content-center flex-column ps-md-4 ps-lg-5">
                            <h2 className='features-title color-secondary font-weight-600'>Keep track of clients</h2>
                            <p className='w-100 detail-text mt-md-3 mt-0 mb-4 mb-md-0 '>
                                Easily maintain a client list and add new clients with detailed profiles. All client information is securely saved in the web app, ensuring you have quick access to client details whenever needed.
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className=' last-sec-margin'>
                    <div className='p-4 p-md-5 pricing-background-secondary-green'>
                        <h1 className='title-text w-100 mb-4'>Hey, we are printers too! </h1>
                        <p className=' color-secondary last-sec-desc-text w-100 mb-0'>
                            Small, medium or large printshop with big dreams? Us too! We don't like to get bogged down in the mundain tasks of quoting, so we fixed it! Run a quote in minutes! Master the Shop. Enjoy the Print!
                        </p>
                    </div>
                </div>
                {/* footer */}
                <div className='footer-section pb-5 position-relative'>
                    <div className='row d-flex justify-content-between footer-img-sec mb-2'>
                        <div className='col-12 col-md-6 mb-2 text-center text-md-start'>
                            <img alt='' src='/assets/images/Print_Shop_Master_svg_logo.svg' className='footer-logo-img' />
                        </div>
                        <div className='col-12 col-md-6 d-flex flex-column text-center text-md-end mt-0 mt-md-4 mb-4 mb-md-0'>
                            <span className='mb-3 icons-span'>
                                <a href="https://www.facebook.com/profile.php?id=61557384680493" target="_blank" rel="noopener noreferrer">
                                    <img alt='' src='/assets/images/facebook.png' className='footer-icon-img fb-icon me-4 me-md-0 cursor-pointer' />
                                </a>
                                <a href="https://www.instagram.com/theprintshopmaster?igsh=MTI2dnE2eno1eDh3aw==" target="_blank" rel="noopener noreferrer">
                                    <img alt="Instagram" src="/assets/images/instagram.png" className="footer-icon-img cursor-pointer" />
                                </a>
                            </span>
                            <span className='mt-1'>
                                <a href="https://youtube.com/channel/UCgxlk2ECFCHduC51XAek0Rw" target="_blank" className="fw-medium landing-page-link footer-link me-4" rel="noreferrer">
                                    LEARN
                                </a>{" "}
                                <a href="mailto:info@theprintshopmaster.com" className="fw-medium landing-page-link footer-link">
                                    CONTACT
                                </a>{" "}
                            </span>
                        </div>
                    </div>
                    <div className='copyright-text font-size-12 d-flex align-items-center justify-content-center justify-content-md-end'>
                        <i className="bx bx-copyright me-1"></i> <span>
                            2024 The PrintShop Master - All Rights reserved
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(LandingPage);