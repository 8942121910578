import { ApiService } from '../../shared/services/api.service';
import { PRINTING_MASTER, Quote, QUOTE_MASTER, USERS } from "../../shared/services/api.config";

export class QuoteCalculatorService {
    apiService = new ApiService();

    // get quote client list
    getQuoteClientList() {
        return this.apiService.get(`${Quote.QUOTE}/quote-client-list`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getCustomerQuoteClientList(customer_uuid) {
        return this.apiService.get(Quote.GET_CUSTOMER_QUOTE_CLIENT_LIST.replace(/{customer_uuid}/, customer_uuid)).then(response => {
            if (response.status === 200){
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async saveQuote(quoteInfo) {
        return await this.apiService.post(Quote.SAVE_QUOTE, quoteInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getGarmentMaterList(user_uuid) {
        return this.apiService.get(QUOTE_MASTER.GARMENT_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getPrintingGarmentMasterList(user_uuid) {
        return this.apiService.get(PRINTING_MASTER.PRINTING_GARMENT_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getStichCountMasterList(user_uuid) {
        return await this.apiService.get(QUOTE_MASTER.STICH_COUNTS_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getPrintingInkCountMatserList(user_uuid) {
        return await this.apiService.get(PRINTING_MASTER.PRINTING_INK_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if(response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getSetUpMasterList(user_uuid) {
        return await this.apiService.get(QUOTE_MASTER.SETUP_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getPrintingSetUpMasterList(user_uuid) {
        return await this.apiService.get(PRINTING_MASTER.PRINTING_SETUP_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getTechnicalSpecificationMasterList(user_uuid) {
        return await this.apiService.get(QUOTE_MASTER.TECHNICAL_SPEFICFICATION_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getPrintingJobCriteriaMasterList(user_uuid) {
        return await this.apiService.get(PRINTING_MASTER.PRINTING_JOB_CRIETERIA_MASTER_LIST.replace(/{user_uuid}/, user_uuid)).then(response => {
            if(response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getPricingMatrixSpecificationMasterList() {
        return await this.apiService.get(QUOTE_MASTER.PRICING_MATRIX_MASTER_LIST).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getuserDetails(user_uuid) {
        return await this.apiService.get(USERS.USER_DETAILS_BY_USER_UUID.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async saveItemToQuote(quoteDetail) {
        return await this.apiService.post(Quote.SAVE_ITEM_TO_QUOTE, quoteDetail).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async deleteQuoteItem(quote_item_uuid) {
        return await this.apiService.delete(Quote.DELETE_QUOTE.replace(/{quote_item_uuid}/, quote_item_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async addItemImg(body) {
        return await this.apiService.post(Quote.ADD_ITEM_IMAGE, body).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async deleteItemImage(body) {
        return await this.apiService.post(Quote.DELETE_ITEM_IMAGE, body).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async setupDefaultrate(setupDefualtData, user_uuid) {
        return await this.apiService.put(QUOTE_MASTER.SETUP_DEFAULT_RATE.replace(/{user_uuid}/, user_uuid), setupDefualtData).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async setupPrintingDefaultRate(setupDefaultData, user_uuid) {
        return await this.apiService.put(PRINTING_MASTER.PRINTING_SETUP_DEFAULT_RATE.replace(/{user_uuid}/, user_uuid), setupDefaultData).then(response => {
            if(response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async resetToDefault(userIdObj) {
        return await this.apiService.post(QUOTE_MASTER.RESET_TO_DEFAULT, userIdObj).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async screenPrintingResetToDefault(userIdObj) {
        return await this.apiService.post(PRINTING_MASTER.PRINTING_RESET_TO_DEFAULT, userIdObj).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }
}